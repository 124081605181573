import axios from 'axios';

export class Relatorio {

  static async modelo1(ano, escola, segmentos, serie, segmento_item, intervaloDatas) {

    const relatorioALL = [];
    const arr = [];
    intervaloDatas == null ? intervaloDatas = [] : intervaloDatas
    const data = await axios.post('/relatorio', {
      ano: ano,
      escola_id: escola,
      segmentos: segmentos,
      series: serie,
      ordem_segmento: segmento_item,
      datas: intervaloDatas
    });

    for (const it of data.data) {
      const alunos_arr = [];
      alunos_arr.push(it.alunos);

      for (const key in alunos_arr) {
        arr.push(alunos_arr[key]);
      }
    }

    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr[i].length; j++) {
        relatorioALL.push(arr[i][j]);
      }
    }

    return relatorioALL;
  }

  static async modelo1Table(relatorioALL) {
    const mywindow = window.open('', 'PRINT', 'height=auto,width=800');
    mywindow.document.write(`<html><head> <title> Relatorio ${relatorioALL[0]['ano']} </title>`);
    mywindow.document.write(`
      <style>
        body {
            margin: 0;
            color: #212529;
            text-align: center;
            background-color: #fff;
        }
        table {
            border-collapse: collapse;
        }
        table tr td, table tr th{
          font-size: 12px !important;
        }
        thead {
          display: table-header-group;
          vertical-align: middle;
          border-color: inherit;
        }
        tr {
          display: table-row;
          vertical-align: inherit;
          border-color: inherit;
        }
        .table-bordered {
            border: 1px solid #dee2e6;
        }
        .table {
            width: 100%;
            margin-bottom: 1rem;
            color: #212529;
        }
        .table-bordered thead td, .table-bordered thead th {
            border-bottom-width: 2px;
        }
        .table thead th {
          vertical-align: bottom;
          border-bottom: 2px solid #dee2e6;
        }
        .table-bordered td, .table-bordered th {
            border: 1px solid #dee2e6;
        }
        .table td, .table th {
          padding: 0.75rem;
        }
      </style>
      `);
    mywindow.document.write('</head><body >');
    mywindow.document.write('<table class="table table-bordered" border=1 cellpadding=5  cellspacing=0>');

    mywindow.document.write(`
      <thead>
        <tr>
          <th> N° </th>
          <th> MATRÍCULA </th>
          <th> TURMA / TURNO </th>
          <th> ALUNOS POR TURMA </th>
          <th> ALUNO(A) </th>
          <th> MÃE E PAI </th>
          <th> NASCIMENTO </th>
          <th> NATURALIDADE </th>
          <th> SEXO </th>
          <th> COR </th>
          <th> CPF </th>
          <th> ENDEREÇO </th>
          <th> PCD </th>
        </tr>
      </thead>`
    );
    for (let i = 0; i < relatorioALL.length; i++) {
      mywindow.document.write(`
        <tbody>
          <tr>
            <td>${i + 1}</td>
            <td>${relatorioALL[i]['aluno']['matricula']}</td>
            <td style="text-align:center;">${relatorioALL[i]['turma_nome'] + '<br><br>' + relatorioALL[i]['turno']}</td>
            <td style="text-align:center;">${relatorioALL[i]['quantidade_alunos']}</td>
            <td>${relatorioALL[i]['aluno']['nome'] + ' ' + relatorioALL[i]['aluno']['sobrenome']}</td>
            <td>${relatorioALL[i]['aluno']['nome_mae'] + '<br><br>' + relatorioALL[i]['aluno']['nome_pai']}</td>
            <td style="text-align:center;">${relatorioALL[i]['aluno']['nascimento_formatado']}</td>
            `);

      if (relatorioALL[i]['aluno']['naturalidade']) {
        mywindow.document.write(`<td style="text-align:center;">${relatorioALL[i]['aluno']['naturalidade']}</td>`);
      } else {
        mywindow.document.write(`
                <td></td>`);
      }

      if (relatorioALL[i]['aluno']['sexo']) {
        mywindow.document.write(`<td style="text-align:center;">${relatorioALL[i]['aluno']['sexo']}</td>`);
      } else {
        mywindow.document.write(`<td></td>`);
      }

      if (relatorioALL[i]['aluno']['cor_raca']) {
        mywindow.document.write(`<td style="text-align:center;">${relatorioALL[i]['aluno']['cor_raca']}</td>`);
      } else {
        mywindow.document.write(`<td></td>`);
      }

      if (relatorioALL[i]['aluno']['cpf']) {
        mywindow.document.write(`
              <td style="text-align:center;">${relatorioALL[i]['aluno']['cpf']}</td>`);
      } else {
        mywindow.document.write(`
              <td></td>`);
      }


      if (relatorioALL[i]['aluno']['enderecos']) {
        mywindow.document.write(`
              <td style="text-align:center; text-transform: uppercase;">
                 ${relatorioALL[i]['aluno']['enderecos']["logradouro"]}
                 ${relatorioALL[i]['aluno']['enderecos']["numero"]}
                 ${relatorioALL[i]['aluno']['enderecos']["bairro"]}
                 ${relatorioALL[i]['aluno']['enderecos']["cidade"]}
                 ${relatorioALL[i]['aluno']['enderecos']["estado"]}
              </td>`);
      } else {
        mywindow.document.write(`
              <td></td>`);
      }


      if (relatorioALL[i]['aluno']['nee']) {
        mywindow.document.write(`
                <td style="text-align:center;">Sim</td>`);
      } else {
        mywindow.document.write(`
                <td style="text-align:center;">Não</td>`);
      }

      mywindow.document.write(`
          </tr>
        </tbody>`
      );

    }

    mywindow.document.write('</table>');
    //mywindow.document.write(document.getElementById('tabela_id').innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus();

    mywindow.print();
    mywindow.close();
  }

  static async livroModelo2(play, individual) {
    if (individual === '0') {
      return await axios.post('/livro-matricula/' + individual, {
        ano: play.ano,
        turma_id: play.turma_id,
      });
    } else {
      return await axios.post('/livro-matricula/' + individual, {
        ano: play.ano,
        aluno_id: play.aluno_id,
      });
    }
  }

  static async fichaIndividual(play, individual) {
    if (individual === '0') {
      return await axios.post('/ficha-individual/' + individual, {
        ano: play.ano,
        turma_id: play.turma_id,
      });
    } else {
      return await axios.post('/ficha-individual/' + individual, {
        ano: play.ano,
        aluno_id: play.aluno_id,
      });
    }
  }

  static async saudeSocial(play, individual) {
    if (individual === '0') {
      return await axios.post('/dados-saude-social/' + individual, {
        ano: play.ano,
        turma_id: play.turma_id,
      });
    } else {
      return await axios.post('/dados-saude-social/' + individual, {
        ano: play.ano,
        aluno_id: play.aluno_id,
      });
    }
  }

}
